import React from "react";
import {
	Grid,
	Typography,
	Menu,
	MenuItem,
	IconButton,
	CircularProgress,
} from "@material-ui/core";
import GaugeChart from "./GaugeChart";
import { coletaService } from "../../services/coleta_service";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { colors } from "../../constants/dc_constants";
import Quadro from "./Quadro";
import { convertData } from "../../util/abb_convert";
import moment from "moment";
import {
	calculateFatorPotencia,
	makeTipoFatorPotencia,
	getShowChartFatorPotencia,
	mediaTensao,
	mediaCorrente,
} from "../../util/gestor_utils";
import { OfflineBolt } from "@material-ui/icons";
import { gestorService } from "../../services/gestor_service";
import { floorTo2 } from "../../util/utils";


class ChartGestorOn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chartSize: 400,
			textDescricaoGestor: "",
			ultimaColeta: null,
			penultimaColeta: null,
			fatorPotencia: 0,
			fatorPotenciaShowChart: 0,
			potenciaAtiva: 0,
			potenciaReativa: 0,
			potenciaAparente: 0,
			potenciaInjetada: 0,
			mediaTensao: 0,
			mediaCorrente: 0,
			tipoFatorPotencia: "",
			anchorEl: null,
			rows: [],
			index: 0,
			intervalosTensaoTensao: [],
			intervalosTensaoNeutro: [],
			medidorABB: 4,
			demanda: 0,
			demandaPorcentagem: 0,
			carregado: false,

			updateInterval: 60000, // 1 minuto
			//toastId: "Toast1",

			labelColors: {
				tensao_l1_l2: null,
				tensao_l2_l3: null,
				tensao_l3_l1: null,
				tensao_l1_neutro: null,
				tensao_l2_neutro: null,
				tensao_l3_neutro: null,
			},

			resumoData: [],
			gestor: this.props.data || {},
			gestores: [],
		};

		this.intervalId = 0;
	}

	async componentDidMount() {
		this.handleSelectGestor(this.props.data);
		this.getGestores();
		this.getUltimasColetas();
		this.getResumoDados();

		try {
			this.intervalId = setInterval(async () => {
				this.getUltimasColetas();
				this.getResumoDados();
			}, this.state.updateInterval);

			this.setState({
				textDescricaoGestor: this.getTextGestor(),
			});
		} catch (e) {
			console.log(e);
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	getGestores = () => {
		if (this.props.showMenu) {
			gestorService.getGestores("power",{}, (response) => {
				if (validateShowResponseToast(response, "", false)) {
					this.setState({
						gestores: response.data,
					});
				}
			});
		}
	};

	getUltimaColetaData = (attribute, simbol = undefined, defaultValue = "") => {
		try {
			if (this.state.ultimaColeta && this.state.ultimaColeta[attribute]) {
				if (simbol) {
					return `${floorTo2(this.state.ultimaColeta[attribute])}${simbol}`;
				} else {
					return floorTo2(this.state.ultimaColeta[attribute]);
				}
			} else {
				return `${defaultValue}${simbol}`;
			}
		} catch (error) {
			return "----";
		}
	};

	updateUIData = (responseData) => {
		try {
			const data = convertData(responseData, this.state.gestor)
			const ultimaColeta = data[1]
			let potenciaAtiva = 0;
			let potenciaInjetada = 0;
			if (ultimaColeta.potencia_ativa_total < 0) {
				potenciaInjetada = ultimaColeta.potencia_ativa_total * -1;
			} else {
				potenciaAtiva = ultimaColeta.potencia_ativa_total;
			}
			const potenciaReativa = ultimaColeta.potencia_reativa_total
			const potenciaAparente = ultimaColeta.potencia_aparente_total
			let fatorPotencia = ultimaColeta.fator_potencia_total ?? calculateFatorPotencia(potenciaAtiva, potenciaAparente)
			const tipoFatorPotencia = makeTipoFatorPotencia(fatorPotencia)
			const fatorPotenciaValues = getShowChartFatorPotencia(fatorPotencia, tipoFatorPotencia)
			const fatorPotenciaShowChart = fatorPotenciaValues[0] || 0
			fatorPotencia = fatorPotenciaValues[1] || 0
			if (data.length > 0) {
				this.setState({
					potenciaAtiva,
					potenciaReativa,
					potenciaAparente,
					potenciaInjetada,
					fatorPotencia,
					tipoFatorPotencia,
					fatorPotenciaShowChart,
					mediaTensao: mediaTensao(
						ultimaColeta.tensao_l1_l2,
						ultimaColeta.tensao_l2_l3,
						ultimaColeta.tensao_l3_l1,
					),
					mediaCorrente: mediaCorrente(
							ultimaColeta.corrente_i1,
							ultimaColeta.corrente_i2,
							ultimaColeta.corrente_i3,
					),
					ultimaColeta: ultimaColeta,
					penultimaColeta: this.state.ultimaColeta,
					index: this.state.index + 1
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	getGestorData = (attribute, defaultValue = null) => {
		try {
			if (this.state.gestor && this.state.gestor[attribute]) {
				return this.state.gestor[attribute];
			}
			return defaultValue;
		} catch (error) {
			return defaultValue;
		}
	};

	getUltimasColetas = () => {
		if (this.state.gestor) {
			coletaService.getUltimasColetas(
				{ gestor_id: this.state.gestor.id },
				(response) => {
					if (validateShowResponseToast(response, "", false, false)) {
						this.setState({
							rows: response.data,
						});
						this.updateUIData(response.data);
					}
				}
			);
		}
	};

	getResumoDados = () => {
		if (this.state.gestor) {
			gestorService.getResumoDados(
				{ gestorId: this.state.gestor.id },
				(response) => {
					if (response.status === 200) {
						const data = response.data;
						const resumoData = {
							kw_dia_reservado: 0,
							kw_dia_fora_ponta: 0,
							kw_dia_ponta: 0,
							kw_7d_reservado: 0,
							kw_7d_fora_ponta: 0,
							kw_7d_ponta: 0,
							kw_30d_reservado: 0,
							kw_30d_fora_ponta: 0,
							kw_30d_ponta: 0,
							ufer_dia_reservado: 0,
							ufer_dia_fora_ponta: 0,
							ufer_dia_ponta: 0,
							ufer_7d_reservado: 0,
							ufer_7d_fora_ponta: 0,
							ufer_7d_ponta: 0,
							ufer_30d_reservado: 0,
							ufer_30d_fora_ponta: 0,
							ufer_30d_ponta: 0,
						}

						if (data[0]) {

							//tratativa para pegar o acumulado do ultimo dia, apenas se esse ultimo dia for hoje
							if (moment(data[0].data_coleta).format("DD") == moment().format("DD")) {
								resumoData.kw_dia_reservado = Math.round(data[0].kw_reservado);
								resumoData.kw_dia_fora_ponta = Math.round(data[0].kw_fora_ponta);
								resumoData.kw_dia_ponta = Math.round(data[0].kw_ponta);
								resumoData.ufer_dia_reservado = Math.round(data[0].uferR);
								resumoData.ufer_dia_fora_ponta = Math.round(data[0].uferFP);
								resumoData.ufer_dia_ponta = Math.round(data[0].uferP);
							}
							data.forEach(value => {
								//tratativa para pegar o acumulado dos ultimos 7 dias
								if (moment(value.data_coleta).isAfter(moment().subtract(7, "day"))) {
									resumoData.kw_7d_reservado += value.kw_reservado;
									resumoData.kw_7d_fora_ponta += value.kw_fora_ponta;
									resumoData.kw_7d_ponta += value.kw_ponta;
									resumoData.ufer_7d_reservado += value.uferR;
									resumoData.ufer_7d_fora_ponta += value.uferFP;
									resumoData.ufer_7d_ponta += value.uferP;
								}
								resumoData.kw_30d_reservado += value.kw_reservado;
								resumoData.kw_30d_fora_ponta += value.kw_fora_ponta;
								resumoData.kw_30d_ponta += value.kw_ponta;
								resumoData.ufer_30d_reservado += value.uferR;
								resumoData.ufer_30d_fora_ponta += value.uferFP;
								resumoData.ufer_30d_ponta += value.uferP;

								if (moment(value.data_coleta).month() == moment().month()
									&& value.kw_max > this.state.demanda) {
									this.state.demanda = value.kw_max;
								}
							}
							);
							this.state.demandaPorcentagem = (this.state.demanda / this.state.gestor.demanda_contratada) * 100;

							resumoData.kw_7d_reservado = Math.round(resumoData.kw_7d_reservado);
							resumoData.kw_7d_fora_ponta = Math.round(resumoData.kw_7d_fora_ponta);
							resumoData.kw_7d_ponta = Math.round(resumoData.kw_7d_ponta);
							resumoData.ufer_7d_reservado = Math.round(resumoData.ufer_7d_reservado);
							resumoData.ufer_7d_fora_ponta = Math.round(resumoData.ufer_7d_fora_ponta);
							resumoData.ufer_7d_ponta = Math.round(resumoData.ufer_7d_ponta);
							resumoData.kw_30d_reservado = Math.round(resumoData.kw_30d_reservado);
							resumoData.kw_30d_fora_ponta = Math.round(resumoData.kw_30d_fora_ponta);
							resumoData.kw_30d_ponta = Math.round(resumoData.kw_30d_ponta);
							resumoData.ufer_30d_reservado = Math.round(resumoData.ufer_30d_reservado);
							resumoData.ufer_30d_fora_ponta = Math.round(resumoData.ufer_30d_fora_ponta);
							resumoData.ufer_30d_ponta = Math.round(resumoData.ufer_30d_ponta);
						};
						this.setState({
							resumoData: resumoData,
							carregado: true,
						});
					};
				}
			)
		}
	}


	getUltimaColetaFormatedDate = () => {
		try {
			return moment(this.state.ultimaColeta.data_coleta).format(
				"DD/MM/YYYY HH:mm:ss"
			);
		} catch (error) {
			return "";
		}
	};

	getTextUltimaDataColeta = () => {
		if (this.state.ultimaColeta && this.state.ultimaColeta.data_coleta) {
			return `Última atualização em ${this.getUltimaColetaFormatedDate()}`;
		}
		return "";
	};

	getTextGestor = () => {
		if (this.state.gestor.id) {
			return `Monitor gestor: ${this.state.gestor.id_equipamento} - ${this.state.gestor.fazenda}`;
		} else {
			return "Nenhum gestor selecionado";
		}
	};

	formatTextNaN = (text) => {
		if (isNaN(text)) {
			return "0";
		}
		return text;
	};

	handleCloseMenu = () => {
		this.setState({
			anchorEl: null,
		});
	};

	handleSelectGestor = (gestor) => {
		if (gestor) {
			this.setState(
				{
					gestor,
					fatorPotencia: 0,
					fatorPotenciaShowChart: 0,
					potenciaAtiva: 0,
					potenciaReativa: 0,
					potenciaAparente: 0,
					potenciaInjetada: 0,
					resumoData: [],
				},
				() => {
					this.getUltimasColetas();
				}
			);
		}
	};

	render() {
		return (
			this.state.medidorABB = this.state.gestor.equipamento == "ABB" ? 4 : 6,
			/*
			Apresentar os paineis de tensão e corrente apenas se o medidor for ABNT
			...Tentei codificar essa feature de uma forma que o programa identifique se
			possui tenção e corrente para mostrar. Não consegui fazer testes devido a falta de
			coletas de medidores ABNT. A medida que esses dados de coletas chegarem, posso
			redefinir essa regra ;)
			*/
			<Grid container spacing={1}>
				<Grid container spacing={2}>
					<Grid
						container
						style={{ paddingLeft: 25 }}
						alignContent="center"
						justifyContent="flex-start"
						item
						xs={12}
						md={this.props.showMenu ? 4 : 6}
					>
						<Typography style={{ color: colors.GREEN }}>
							{this.getTextGestor()}
						</Typography>
					</Grid>
					<Grid
						container
						style={{ paddingRight: 50 }}
						alignContent="center"
						justifyContent={this.props.showMenu ? "center" : "flex-end"}
						item
						xs={12}
						md={this.props.showMenu ? 4 : 6}
					>
						<Typography style={{ color: colors.GREEN }}>
							{this.getTextUltimaDataColeta()}
						</Typography>
					</Grid>
					{this.props.showMenu ? (
						<Grid
							container
							style={{ paddingRight: 40 }}
							justifyContent="flex-end"
							item
							xs={12}
							md={4}
						>
							<div>
								<IconButton
									onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
									onMouseOver={(e) =>
										this.setState({ anchorEl: e.currentTarget })
									}
								>
									<OfflineBolt style={{ color: colors.GREEN }} />
									<Typography style={{ color: colors.GREEN }}>
										{" "}
										Gestores
									</Typography>
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={this.state.anchorEl}
									keepMounted
									open={Boolean(this.state.anchorEl)}
									onClose={this.handleCloseMenu}
									MenuListProps={{
										onMouseLeave: () => {
											this.setState({ anchorEl: false });
										},
									}}
								>
									{this.state.gestores.map((gestor) => {
										return (
											<MenuItem
												onClick={() => {
													this.handleSelectGestor(gestor);
												}}
											>
												{`${gestor.id_equipamento} - ${gestor.fazenda || ""}`}
											</MenuItem>
										);
									})}
								</Menu>
							</div>
						</Grid>
					) : (
						<div />
					)}
				</Grid>
				<Grid container item xs={12} spacing={6}>

					{this.state.medidorABB == 4 && (
						<Grid item xs={12} sm={6} md={this.state.medidorABB}>
							<GaugeChart
								title="Tensão (V)"
								label="V"
								max={
									Math.round(
										this.getGestorData("transf_potencia1") * Math.sqrt(3) * 1.3
									) || 300
								}
								value={floorTo2(this.state.mediaTensao)}
								redFrom={0}
								redTo={floorTo2(
									this.getGestorData("transf_potencia1") * Math.sqrt(3) * 0.86
								)} // Cŕitico baixo
								yellowColor="#DC3912"
								yellowFrom={floorTo2(
									this.getGestorData("transf_potencia1") * Math.sqrt(3) * 1.06
								)} // Crítico alto
								yellowTo={floorTo2(
									Math.round(
										this.getGestorData("transf_potencia1") * Math.sqrt(3) * 1.3
									)
								)}
								greenFrom={floorTo2(
									this.getGestorData("transf_potencia1") * Math.sqrt(3) * 0.91
								)} // Inicio bom
								greenTo={floorTo2(
									this.getGestorData("transf_potencia1") * Math.sqrt(3) * 1.04
								)}
								chartSize={this.state.chartSize}
							>
								{() => (
									<Grid container>
										<Typography
											variant="h5"
											xs={12}
											align="center"
											style={{
												color: "white",
												width: "100%",
												fontSize: "calc(0.80em + 0.8vw)",
											}}
										>
											{`L1 - ${this.formatTextNaN(
												Number(this.getUltimaColetaData("tensao_l1_l2")).toFixed(
													2
												)
											)}`}
										</Typography>
										<Typography
											variant="h5"
											xs={12}
											align="center"
											style={{
												color: "white",
												width: "100%",
												fontSize: "calc(0.80em + 0.8vw)",
											}}
										>
											{`L2 - ${this.formatTextNaN(
												Number(this.getUltimaColetaData("tensao_l2_l3")).toFixed(
													2
												)
											)}`}
										</Typography>
										<Typography
											variant="h5"
											xs={12}
											align="center"
											style={{
												color: "white",
												width: "100%",
												fontSize: "calc(0.80em + 0.8vw)",
											}}
										>
											{`L3 - ${this.formatTextNaN(
												Number(this.getUltimaColetaData("tensao_l3_l1")).toFixed(
													2
												)
											)}`}
										</Typography>
									</Grid>
								)}
							</GaugeChart>
						</Grid>
					)}

					{this.state.medidorABB == 4 && ( //Apresentar o painel de corrente apenas se o medidor estiver coletando corrente
						<Grid item xs={12} sm={6} md={this.state.medidorABB}>
							<GaugeChart
								title="Corrente (A)"
								label="A"
								max={floorTo2(this.getGestorData("soma_cargas", 300) * 1.3)}
								value={this.state.mediaCorrente}
								greenFrom={0}
								greenTo={floorTo2(this.getGestorData("soma_cargas", 300))}
								redFrom={floorTo2(this.getGestorData("soma_cargas", 300))}
								redTo={floorTo2(this.getGestorData("soma_cargas", 300) * 1.3)}
								chartSize={this.state.chartSize}
							>
								{() => (
									<Grid container>
										<Typography
											variant="h5"
											xs={12}
											align="center"
											style={{
												color: "white",
												width: "100%",
												fontSize: "calc(0.80em + 0.8vw)",
											}}
										>
											{`L1 - ${this.formatTextNaN(
												Number(this.getUltimaColetaData("corrente_i1")).toFixed(2)
											)}`}
										</Typography>
										<Typography
											variant="h5"
											xs={12}
											align="center"
											style={{
												color: "white",
												width: "100%",
												fontSize: "calc(0.80em + 0.8vw)",
											}}
										>
											{`L2 - ${this.formatTextNaN(
												Number(this.getUltimaColetaData("corrente_i2")).toFixed(2)
											)}`}
										</Typography>
										<Typography
											variant="h5"
											xs={12}
											align="center"
											style={{
												color: "white",
												width: "100%",
												fontSize: "calc(0.80em + 0.8vw)",
											}}
										>
											{`L3 - ${this.formatTextNaN(
												Number(this.getUltimaColetaData("corrente_i3")).toFixed(2)
											)}`}
										</Typography>
									</Grid>
								)}
							</GaugeChart>
						</Grid>
					)}

					<Grid item xs={12} sm={6} md={this.state.medidorABB}>
						<GaugeChart
							title="Potência Ativa (kW)"
							label="kW"
							max={floorTo2(
								this.getGestorData("demanda_contratada", 200) * 1.05 * 1.2
							)}
							value={this.state.potenciaAtiva}
							greenFrom={0}
							greenTo={this.getGestorData("demanda_contratada", 200)}
							yellowFrom={this.getGestorData("demanda_contratada", 200)}
							yellowTo={this.getGestorData("demanda_contratada", 200) * 1.05}
							redFrom={this.getGestorData("demanda_contratada", 200) * 1.05}
							redTo={this.getGestorData("demanda_contratada", 200) * 1.05 * 1.2}
							chartSize={this.state.chartSize}
						>
							{() => (
								<Grid container>
									<Typography
										variant="h5"
										xs={12}
										align="center"
										style={{
											color: "white",
											width: "100%",
											fontSize: "calc(0.80em + 0.8vw)",
										}}
									>
										{`Maior demanda do mês: ${this.formatTextNaN(
											Number(Math.round(this.state.demanda)))}
										(${this.formatTextNaN(
												Number(this.state.demandaPorcentagem.toFixed(1)))}%)`}
									</Typography>
								</Grid>
							)}
						</GaugeChart>
					</Grid>
					<Grid item xs={12} sm={6} md={this.state.medidorABB}>
						<GaugeChart
							title="Fator de Potência"
							label="FP"
							min={0}
							max={2}
							value={this.state.fatorPotencia}
							redFrom={0}
							redTo={0.92}
							yellowColor="#DC3912"
							yellowFrom={1.08}
							yellowTo={2}
							greenFrom={0.92}
							greenTo={1.08}
							labelText={this.state.tipoFatorPotencia}
							labelColor={colors.GREEN}
							chartSize={this.state.chartSize}
							showValue={this.state.fatorPotenciaShowChart}
							majorTicks={[
								"0 IND",
								"0.25",
								"0.50",
								"0.75",
								"1",
								"-0.75",
								"-0.50",
								"-0.25",
								"CAP 0",
							]}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={this.state.medidorABB}>
						{this.state.gestor.demanda_geracao ?
						<GaugeChart
						title="Potência Injetada (kW)"
						label="kW"
						max={floorTo2(
							this.getGestorData("demanda_geracao", 200) * 1.05 * 1.2
						)}
						value={this.state.potenciaInjetada}
						greenFrom={0}
						greenTo={this.getGestorData("demanda_geracao", 200)}
						yellowFrom={this.getGestorData("demanda_geracao", 200)}
						yellowTo={this.getGestorData("demanda_geracao", 200) * 1.05}
						redFrom={this.getGestorData("demanda_geracao", 200) * 1.05}
						redTo={this.getGestorData("demanda_geracao", 200) * 1.05 * 1.2}
						chartSize={this.state.chartSize}
					/>
						:
						<GaugeChart
						title="Potência Aparente (kVA)"
						label="kVA"
						max={floorTo2(this.getGestorData("potencia_transf", 380) * 1.2)}
						value={this.state.potenciaAparente}
						greenFrom={0}
						greenTo={this.getGestorData("potencia_transf", 380)}
						redFrom={this.getGestorData("potencia_transf", 380)}
						redTo={this.getGestorData("potencia_transf", 380) * 1.2} // Potencia + 20%
						chartSize={this.state.chartSize}
						/>
					}
					</Grid>
					<Grid item xs={12} sm={6} md={this.state.medidorABB}>
						{this.state.carregado ? (
							<Quadro
								data={this.state.resumoData}
								gestorName={this.state.gestor.id_equipamento}
							></Quadro>
						) : (
							<div style={{
								display: "flex",
								justifyContent: "center",
								alignItems: 'center',
								height: '100%'
							}}>
								<CircularProgress
									style={{ color: colors.ORANGE_POWER }}
									size={100}
								/>
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		);
	}

	styles = {
		grid: {
			backgroundColor: colors.LIGHT_GREY,
		},

		typography: {
			backgroundColor: colors.PRIMARY,
			color: colors.SECONDARY,
			padding: 5,
			textAlign: "center",
			fontSize: "calc(5px + 1vw)",
		},

		bottomTypography: {
			backgroundColor: colors.DARK_GREY,
			color: "yellow",
			padding: 5,
			textAlign: "center",
			fontSize: "calc(5px + 1vw)",
		},
	};
}

export default ChartGestorOn;
